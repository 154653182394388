import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import HoneybadgerVue from "@honeybadger-io/vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
require("./assets/main.css");

import "vue3-toastify/dist/index.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createPinia } from "pinia";
import Honeybadger from "@honeybadger-io/js";

const config = {
  apiKey: "hbp_STt9OKydfBPFKOdmY7VvEvHvWK8x9M2s2ZVG",
  environment: "production",
};

const app = createApp(App);

app.use(store);
app.use(router);
app.use(BootstrapVue);
app.use(BootstrapVueIcons);
app.use(HoneybadgerVue, config);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(createPinia());

app.config.errorHandler = (err, vm, info) => {
  console.error("Global error handler:", err, vm, info);
  if (vm) {
    // Handle the error with Honeybadger
    Honeybadger.notify(err, {
      context: {
        component: vm.$options.name,
        propsData: vm.$props,
        info,
      },
    });
  } else {
    // Handle the error without vm
    Honeybadger.notify(err, {
      context: {
        info,
      },
    });
  }
};

app.mount("#app");
