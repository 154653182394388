import client from "./http-common";

class CustomerService {
  fetch() {
    return client.get("customers");
  }
  get(id) {
    return client.get("customers/" + id);
  }
  simplify() {
    return client.get("customers/simplify2");
  }
  create(customer) {
    return client.post("customer/create", customer);
  }
  update(customer) {
    return client.put(`customers/${customer.id}`, customer);
  }
  delete(id) {
    return client.delete(`customers/${id}`);
  }
}

export default new CustomerService();
