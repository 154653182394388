import messageService from "@/services/message.service";
import customerService from "@/services/customer.service";
import moment from "moment";
import { defineStore } from "pinia";
import _ from "lodash";
import { toRaw } from "vue";

export const useReportStore = defineStore("report", {
  state: () => {
    return {
      showError: false,
      from: new Date(),
      to: new Date(),
      reports: [],
      customers: [],
      selectedCustomers: [],
      checkAll: false,
      findWinningNumber: false,
    };
  },

  getters: {
    items(state) {
      const fields = [
        { key: "customer", label: "Khách hàng" },
        { key: "mn", label: "Miền Nam" },
        { key: "mt", label: "Miền Trung" },
        { key: "mb", label: "Miền Bắc" },
        { key: "total", label: "Tổng" },
        { key: "action", label: "" },
      ];
      const items = _.map(state.reports, (report) => {
        let result = {
          customer: report.customer_name,
          total:
            report.total.profit == report.total.commission
              ? report.total.profit
              : `${report.total.profit}(${report.total.commission})`,
          status: report.total.commission < 0 ? "minus" : "plus",
          detail: report,
        };

        for (const key of ["mn", "mt", "mb"]) {
          if (key in report) {
            result[key] =
              report[key].profit == report[key].commission
                ? report[key].profit
                : `${report[key].profit}(${report[key].commission})`;
          }
        }

        return result;
      });

      return {
        fields,
        items,
      };
    },

    totalTextToCopy: (row) => {
      const { customer_name, mn, mt, mb, total } = row;
      return `${customer_name} | MN: ${mn} | MT: ${mt} | MB: ${mb} | Tổng: ${total}`;
    },
  },

  actions: {
    init() {
      return customerService.fetch().then((res) => {
        const data = res.data;
        this.customers = _.map(data, (item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      });
    },
    fetch() {
      const from = moment(this.from).format("DD-MM-YYYY");
      const to = this.findWinningNumber ? from : moment(this.to).format("DD-MM-YYYY");
      const customerIds = toRaw(this.selectedCustomers);

      return messageService.report(from, to, customerIds, this.findWinningNumber).then(
        (res) => {
          this.showError = _.isEmpty(res.data.data);
          this.reports = res.data.data;
          return Promise.resolve();
        },
        () => {
          return Promise.reject();
        }
      );
    },
  },
});
