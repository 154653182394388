import { createWebHistory, createRouter } from "vue-router";
import Login from "../views/Login.vue";
import Notification from "../views/Notification.vue";
import Settings from "../views/Settings.vue";
import Report from "../views/Report.vue";
import Customers from "../views/customers/Customers.vue";
import Feedback from "../views/Feedback.vue";
import Syntax from "../views/Syntax.vue";
import Syntax2 from "../views/Syntax2.vue";
import Calculate from "../views/Calculate.vue";
import Lottery from "../views/Lottery.vue";
import Password from "../views/Password.vue";
import Manager from "../views/Manager.vue";
import Manager2 from "../views/Manager2.vue";
import Manager3 from "../views/Manager3.vue";
import CustomersForm from "@/views/customers/CustomersForm.vue";
import MessageError from "../views/MessageError.vue";
import Honeybadger from "@honeybadger-io/js";

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/notification",
    component: Notification,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/report",
    component: Report,
  },
  {
    path: "/customers",
    component: Customers,
  },
  {
    path: "/feedback",
    component: Feedback,
  },
  {
    path: "/feedback/:id",
    component: Feedback,
  },
  {
    path: "/syntax",
    component: Syntax,
  },
  {
    path: "/syntax2",
    component: Syntax2,
  },
  {
    path: "/calculate",
    component: Calculate,
  },
  {
    path: "/calculate/:id",
    component: Calculate,
  },
  {
    path: "/lottery",
    component: Lottery,
  },
  {
    path: "/password",
    component: Password,
  },
  {
    path: "/manager",
    component: Manager,
  },
  {
    path: "/manager2",
    component: Manager2,
  },
  {
    path: "/manager3",
    component: Manager3,
  },
  {
    path: "/customers/create",
    component: CustomersForm,
  },
  {
    path: "/customers/:id",
    component: CustomersForm,
  },
  {
    path: "/message-error",
    component: MessageError,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_TITLE || "Tính tiền";

  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  const user = loggedIn ? JSON.parse(localStorage.getItem("user")) : "";

  if (loggedIn) {
    Honeybadger.setContext({
      username: user.username,
    });
  }
  if ((to.path == "/manager" || to.path == "/manager2" || to.path == "/manager3") && user.username != "bi") {
    next("/notification");
    return;
  }

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
