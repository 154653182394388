<script>
export default {
  name: "ReportComponent",
};
</script>

<script setup>
import { useReportStore } from "@/stores/report.module";
import { useCommonStore } from "@/stores/common.module";
import { region_text, type_text } from "@/utils/constants";
import { computed } from "vue";
import ReportDetail from "@/components/ReportDetail.vue";

const reportStore = useReportStore();
const commonStore = useCommonStore();

reportStore.init();

function report() {
  reportStore.$state.loading = true;

  reportStore
    .fetch()
    .catch(() => commonStore.err("Có lỗi trong quá trình xử lý"))
    .finally(() => (reportStore.$state.loading = false));
}

function toggleCheckAll() {
  reportStore.$state.selectedCustomers = reportStore.$state.checkAll
    ? reportStore.$state.customers.map((item) => item.value)
    : [];
}

function generateTextToCopy(row) {
  let text = "";
  for (const key of ["mn", "mb", "mt", "total"]) {
    if (key in row.item.detail) {
      if (key != "total") {
        text += `${region_text[key]}: \n`;
      } else {
        text += "Tổng thu chi: \n";
      }

      for (const key_detail of ["2s", "3s", "4s", "dd", "xc", "dt", "dc"]) {
        if (
          row.item.detail[key][`money_${key_detail}`] != 0 ||
          row.item.detail[key][`win_${key_detail}`] != 0 ||
          row.item.detail[key][`profit_${key_detail}`] != 0
        ) {
          text += `${type_text[key_detail]}: ${row.item.detail[key][`money_${key_detail}`]} | Trúng: ${
            row.item.detail[key][`win_${key_detail}`]
          }\n`;
        }
      }

      const total = row.item.detail[key].profit || row.item.detail[key].commission;
      text += `Tổng ${total < 0 ? "Thu" : "Bù"}: ${total}\n\n\n`;
    }
  }

  navigator.clipboard.writeText(text).then(() => {
    commonStore.success("Đã sao chép");
  });
}

function rowClass(item, type) {
  if (!item || type !== "row") return;
  if (item.status === "minus") return "minus";
}

const data = computed(() => reportStore.items);
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <b-col cols="6" md="5">
        <h1>Thống kê</h1>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col>
        <b-form-group label="Ngày bắt đầu" v-if="!reportStore.$state.findWinningNumber">
          <b-form-datepicker v-model="reportStore.$state.from"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Ngày dò số" v-if="reportStore.$state.findWinningNumber">
          <b-form-datepicker v-model="reportStore.$state.from"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!reportStore.$state.findWinningNumber">
      <b-col>
        <b-form-group label="Ngày kết thúc">
          <b-form-datepicker v-model="reportStore.$state.to"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-form-checkbox v-model="reportStore.$state.findWinningNumber"> Dò số và thống kê theo ngày </b-form-checkbox>
    </b-row>
    <br />
    <b-row>
      <b-form-group label="Khách hàng" v-slot="{ ariaDescribedby }">
        <b-form-checkbox v-model="reportStore.$state.checkAll" @change="toggleCheckAll">
          Chọn hết tất cả
        </b-form-checkbox>
        <br />
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="reportStore.$state.selectedCustomers"
          :options="reportStore.$state.customers"
          :aria-describedby="ariaDescribedby"
        ></b-form-checkbox-group>
      </b-form-group>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <b-form-group label="" for="btn">
          <b-button id="btn" variant="primary" block @click.prevent="report">
            {{ reportStore.$state.findWinningNumber ? "Dò số và thống kê" : "Thống kê" }}
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="pt-5" v-if="reportStore.$state.showError">
      <b-col cols="12" md="6" class="mx-auto" style="color: red">
        <h3 style="text-align: center">Không có dữ liệu để thống kê</h3>
      </b-col>
    </b-row>

    <div class="pt-5" v-if="data.items.length > 0">
      <b-table bordered hover :items="data.items" :fields="data.fields" :tbody-tr-class="rowClass">
        <template #cell(action)="row">
          <b-button size="sm" @click="generateTextToCopy(row)" style="margin-right: 10px"> Copy </b-button>
          <b-button size="sm" @click="row.toggleDetails"> {{ row.detailsShowing ? "Ẩn" : "Hiện" }} chi tiết </b-button>
        </template>
        <template #row-details="row">
          <b-card>
            <b-row>
              <report-detail :detail="row.item.detail.mn" region="Miền Nam" />
              <report-detail :detail="row.item.detail.mt" region="Miền Trung" />
              <report-detail :detail="row.item.detail.mb" region="Miền Bắc" />
            </b-row>
          </b-card>
        </template>
      </b-table>
    </div>
  </b-container>
</template>

<style>
.minus {
  color: red;
}
.b-table tbody tr:last-child {
  color: black !important;
  font-weight: bold !important;
}

.b-table tbody tr td:nth-child(6) {
  min-width: 190px;
}
</style>
