<script>
export default {
  name: "CustomersComponent",
};
</script>

<script setup>
import { useCustomerStore, customer_fields } from "@/stores/customer.module";
import { profit_values, sign_values, bonus_dt_type_values } from "@/utils/constants";
import _ from "lodash";

import { ref, getCurrentInstance } from "vue";

const store = useCustomerStore();

const internalInstance = getCurrentInstance();
const confirmMessage = ref("");

function del(id, name) {
  console.log(id, name);
  const bvModal = internalInstance.proxy._bv__modal;

  confirmMessage.value = `Bạn có chắc muốn xoá khách hàng ${name} ?`;
  bvModal
    .msgBoxConfirm(confirmMessage.value, {
      size: "md",
      buttonSize: "sm",
      okVariant: "danger",
      cancelVariant: "secondary",
      okTitle: "Xoá",
      cancelTitle: "Huỷ",
    })
    .then((value) => {
      if (value) {
        store.delete(id);
      }
    });
}

store.fetch();
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols style="text-align: center">
        <h1>Quản lý khách hàng</h1>
      </b-cols>
    </b-row>
    <b-row style="padding: 30px 0px">
      <b-col style="text-align: center">
        <a href="/customers/create">Thêm khách hàng</a>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table :items="store.$state.customers" :fields="customer_fields">
          <template #cell(name)="data">
            <a :href="`/customers/${data.item.id}`">{{ data.value }}</a>
          </template>
          <template #cell(bonus_dt_type)="data">
            {{ _.find(bonus_dt_type_values, { value: data.value })?.text }}
          </template>
          <template #cell(bonus_dt_type_mb)="data">
            {{ _.find(bonus_dt_type_values, { value: data.value })?.text }}
          </template>
          <template #cell(profit_type)="data">
            {{ _.find(profit_values, { value: data.value })?.text }}
          </template>
          <template #cell(sign)="data">
            {{ _.find(sign_values, { value: data.value })?.text }}
          </template>
          <template #cell(action)="row">
            <b-button @click.prevent="del(row.item.id, row.item.name)" variant="danger">
              <b-icon-trash-fill></b-icon-trash-fill>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.title {
  vertical-align: middle !important;
  text-align: center;
}

.b-table > tbody > tr:last-child {
  font-weight: normal !important; /* Override the bold style */
}
</style>
