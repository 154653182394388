export const sign_values = [
  { text: "Khách", value: 1 },
  { text: "Thầu", value: -1 },
];
export const bonus_dt_type_values = [
  { text: "Thưởng chi rưỡi", value: "bonus" },
  { text: "Không thưởng", value: "none" },
];

export const commission_calculation_type_values = [
  { text: "Không tính", value: "none" },
  { text: "Chỉ khi có lãi", value: "on_profit" },
  { text: "Luôn luôn", value: "always" },
];

export const profit_values = [
  {
    value: "0",
    text: "Lai từng số",
  },
  {
    value: "1",
    text: "Lai tổng phơi",
  },
  {
    value: "2",
    text: "Lai tổng 2 số, 3 số",
  },
];

export const region_text = {
  mn: "Miền Nam",
  mt: "Miền Trung",
  mb: "Miền Bắc",
  total: "Tổng",
};

export const empty_customer = {
  id: 0,
  name: "",
  profit_type: "0",
  commission_calculation_type: "none",
  allow_dc_2d: "default",
  sign: 1,
  bonus_dt_type: "bonus",
  bonus_dt_type_mb: "none",
  customersNumberSettings: [
    {
      region: "mn",
      haiso: "0.750",
      baso: "0.650",
      bonso: "0.650",
      dd: "0.750",
      xc: "0.650",
      dt: "0.750",
      dc: "0.750",
      trunghaiso: "75",
      trungbaso: "650",
      trungbonso: "6000",
      trungdd: "75",
      trungxc: "650",
      trungdt: "780",
      trungdc: "580",
    },
    {
      region: "mt",
      haiso: "0.750",
      baso: "0.650",
      bonso: "0.650",
      dd: "0.750",
      xc: "0.650",
      dt: "0.750",
      dc: "0.750",
      trunghaiso: "75",
      trungbaso: "650",
      trungbonso: "6000",
      trungdd: "75",
      trungxc: "650",
      trungdt: "780",
      trungdc: "580",
    },
    {
      region: "mb",
      haiso: "0.750",
      baso: "0.650",
      bonso: "0.650",
      dd: "0.750",
      xc: "0.650",
      dt: "0.750",
      dc: "0.750",
      trunghaiso: "75",
      trungbaso: "650",
      trungbonso: "6000",
      trungdd: "75",
      trungxc: "650",
      trungdt: "780",
      trungdc: "580",
    },
  ],
};

export const listNumbersTableFields = [
  { key: "province_index", label: "Đài", sortable: true },
  { key: "number", label: "Số", sortable: true },
  { key: "numbertypeid", label: "Kiểu đánh", sortable: true },
  { key: "money", label: "Tiền", sortable: true },
];

export const SYNTAX_TITLE = {
  1: "Bao",
  2: "Bao lô đảo",
  3: "Xỉu chủ",
  4: "Xỉu chủ đảo",
  11: "Xỉu chủ đảo đầu",
  12: "Xỉu chủ đảo đuôi",
  5: "Đầu",
  6: "Đuôi",
  7: "Đầu đuôi",
  8: "Đá thẳng",
  9: "Đá chéo",
  10: "Bảy lô",
  13: "Bảo lô đảo",
};

export const PROVINCE_TITLE = {
  mt: {
    0: { "1,2": "2 đài", "1,2,3": "3 đài" },
    1: { 1: "Kon tum", 2: "Khánh Hoà", 3: "Thừa Thiên Huế" },
    2: { 1: "Phú Yên", 2: "Thừa Thiên Huế" },
    3: { 1: "Đắk Lắk", 2: "Quảng Nam" },
    4: { 1: "Đà Nẵng", 2: "Khánh Hoà" },
    5: { 1: "Bình Định", 2: "Quảng Trị", 3: "Quảng Bình" },
    6: { 1: "Gia Lai", 2: "Ninh Thuận" },
    7: { 1: "Đà Nẵng", 2: "Quảng Ngãi", 3: "Đắk Nông" },
  },
  mn: {
    0: { "1,2": "2 đài", "1,2,3": "3 đài", "1,2,3,4": "4 đài" },
    1: { 1: "Tiền Giang", 2: "Kiên Giang", 3: "Đà Lạt" },
    2: { 1: "Thành Phố", 2: "Đồng Tháp", 3: "Cà Mau" },
    3: { 1: "Bến Tre", 2: "Vũng Tàu", 3: "Bạc Liêu" },
    4: { 1: "Đồng Nai", 2: "Cần Thơ", 3: "Sóc Trăng" },
    5: { 1: "Tây Ninh", 2: "An Giang", 3: "Bình Thuận" },
    6: { 1: "Vĩnh Long", 2: "Bình Dương", 3: "Trà Vinh" },
    7: { 1: "Thành Phố", 2: "Long An", 3: "Bình Phước", 4: "Hậu Giang" },
  },
};

export const type_text = {
  "2s": "Hai số",
  "3s": "Ba số",
  "4s": "Bốn số",
  dd: "Đầu đuôi",
  xc: "Xỉu chủ",
  dt: "Đá thẳng",
  dc: "Đá chéo",
};

export const customer_allow_dc_2d = {
  default: "Mặc định",
  yes: "Có",
  no: "Không",
};
